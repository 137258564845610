.loader {
  border: 8px solid #f3f3f3; /* Light gray border */
  border-top: 8px solid hwb(246 18% 16%); /* Blue border for animation */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin-top: 50px;
  animation: spin 1.5s linear infinite; /* Animation properties */
   /* Center the loader */
  position: absolute; /* Positioning context for centering */
   /* Position the loader at the bottom */
  left: 50%; /* Move the loader 50% from the left */
  transform: translateX(-50%); /* Center the loader horizontally */
}

  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }