.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.password-toggle-icon {
  position: absolute;
  top: 2px;
  right: 4px;
  cursor: pointer;
  font-size: 15px;
  background-color: white;
  padding: 4px 4px;
}
.input-password[type='password'] {
  font-family: 'Arial', sans-serif;
  letter-spacing: 1px;
  -webkit-text-security: disc;
}

.placeholder::placeholder {
  color: gray;
}



.arrow-box {
  display: flex;
  flex-direction: column;
  align-items: center;

}

.arrow-up {
  height: 7px;
  color: #555;
   /* Default color for up arrow */
}

.arrow-down {
  color: #555; /* Default color for down arrow */
}

.highlight {
  color: #007bff; /* Highlighted color for active arrow */
}
.ascding {
  display: flex;
  gap: 10px;
  align-items: center;
}



.black-color {
  color: black;
}

.blue-color {
  color: blue;
}

.perpal-color {
  color: purple;
}

.light-red-color {
  color: lightcoral;
}

.light-green-color {
  color: lightgreen;
}

.green-color {
  color: green;
}

.red-color {
  color: red;
}


/* ... your existing styles ... */

.sidenav {
  transition: transform 0.3s ease-in-out;
}

/* Apply the 'open' state style */
.sidenav.open {
  transform: translateX(0);
}

/* Apply the 'open' state style for the main content area */
#app-content {
  transition: margin-left 0.3s ease-in-out;
}

/* Adjust main content margin when sidebar is open */
#app-content.open {
  margin-left: 260px; /* Adjust this value based on your sidebar width */
}
.responsive-button {
  margin-left: 0px !important ;
}
@media (max-width: 575px) {
  .responsive-button {
    margin-left: 50px !important ;
  }
}





