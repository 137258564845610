.hov:focus{
    background-color: blue;
}

.hide {
    display: none;
  }
  
  /* Define the show class to show the sidebar */
  .show {
    display: block; /* Or any other appropriate display value */
  }

  .sidenav{
    display: flex;
    width: 160px;
    justify-content: space-between; /* This pushes the button to the right */
  }

  /* Custom CSS for responsive sidebar */
@media (max-width: 991.98px) {
  .sidenav {
    display: none;
  }

  .d-lg-block.sidenav {
    display: block;
  }
}

.sidenav {
  width: 250px;
  transition: transform 0.3s ease-in-out;
}

/* Show Sidebar */
.sidenav.show {
  transform: translateX(0);
}

/* Hide Sidebar */
.sidenav.hide {
  transform: translateX(-100%);
}

/* Responsive Sidebar */
@media (max-width: 768px) {
  .sidenav {
    width: 200px;
  }
}

@media (max-width: 576px) {
  .sidenav {
    width: 150px;
  }
}









 
  
  
  
  
  
  
  