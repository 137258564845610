.usertable th{
    border: 1px solid black;
    border-collapse: collapse;
    text-align: center;
    font-size: small;
}
.usertable td{
    border: 1px solid black;
    border-collapse: collapse;
    text-align: center;
    font-size: small;
}
.datepicker{
    width: 100%;
    font-size: 15px;
    height: 35px;
}
.react-datepicker-wrapper{
    display: block !important;
}